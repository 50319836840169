<template>
  <main>
    <div class="layout-subcontainer">
      <div class="box-wrap">
        <h1 class="tit-h1 d-flex">
          기간별 상담완료현황
          <!-- 조회 버튼 -->
          <div class="ml-auto align-self-center">
            <v-btn class="btn-etc2" outlined small @click="getListData">조회</v-btn>
          </div>
        </h1>
        <!-- 조회 -->
        <div class="box-ct mt-2">
          <div class="table-form d-flex">
            <table>
              <caption class="hide">
                조회 항목
              </caption>
              <colgroup>
                <col width="148px" />
                <col width="240px" />
                <col width="148px" />
                <col width="240px" />
                <col width="148px" />
                <col width="240px" />
                <col width="148px" />
                <col width="170px" />
              </colgroup>
              <tbody>
                <tr>
                  <th scope="row">
                    <v-badge dot color="#EF9191">
                      건설사
                    </v-badge>
                  </th>
                  <td>
                    <div class="form-inp full">
                      <v-autocomplete
                        v-model="CTCP_CODE"
                        :items="ctcpTypeCd"
                        item-text="CD_NM"
                        item-value="CD"
                        outlined
                        hide-details
                        hide-no-data
                        placeholder="선택하세요"
                        @change="changeBussType(CTCP_CODE)"
                        >
                      </v-autocomplete>
                    </div>
                  </td>
                  <th scope="row">
                    <v-badge dot color="#EF9191">
                      사업지명
                    </v-badge>
                  </th>
                  <td>
                    <div class="form-inp full">
                      <v-autocomplete
                        v-model="BUSS_CODE"
                        :items="bussTypeCd"
                        item-text="CD_NM"
                        item-value="CD"
                        outlined
                        hide-details
                        hide-no-data
                        placeholder="선택하세요"
                        :disabled="bussTypeCd.length === 0 ? true : false"
                        @change="changeCmpType(BUSS_CODE)"
                      >
                      </v-autocomplete>
                    </div>
                  </td>
                  <th scope="row">
                    캠페인
                  </th>
                  <td>
                    <div class="form-inp full">
                      <v-autocomplete
                        v-model="CMP_ID"
                        :items="cmpTypeCd"
                        item-text="CMP_NAME"
                        item-value="CMP_ID"
                        outlined
                        hide-details
                        hide-no-data
                        placeholder="선택하세요"
                        :disabled="cmpTypeCd.length === 0 ? true : false"
                      >
                      </v-autocomplete>
                    </div>
                  </td>
                  <th scope="row">
                    <v-badge dot color="#EF9191">
                      조회기간
                    </v-badge>
                  </th>
                  <td>
                    <v-menu 
                      v-model="menu1" 
                      :close-on-content-click="false" 
                      :nudge-left="290"
                      transition="scale-transition" 
                      offset-y 
                      min-width="auto" 
                      content-class="calendar-modal"
                      color="#FFF"
                    >
                      <template v-slot:activator="{ on, attrs }">
                        <v-text-field
                          class="form-inp full icon-calendar"
                          v-model="dateRangeText"
                          v-bind="attrs"
                          v-on="on"
                          readonly
                          outlined
                          hide-details
                          append-icon="svg-calendar"
                        ></v-text-field>
                      </template>
                      <div class="datepicker-range">
                        <v-date-picker
                          v-model="DATE_FROM"
                          no-title
                          color="#F4B228"
                          locale="ko-KR"
                          :max="DATE_TO"
                          :first-day-of-week="0" 
                          :day-format="mixin_getDate" 
                          @change="startDate"
                        ></v-date-picker>
                        <v-date-picker
                          v-model="DATE_TO"
                          no-title
                          color="#F4B228"
                          locale="ko-KR"
                          :first-day-of-week="0" 
                          :min="DATE_FROM"
                          :day-format="mixin_getDate" 
                          @change="endDate"
                        ></v-date-picker>
                      </div>
                      <div class="text-center pt-3 pb-3">
                        <v-btn outlined small class="btn-point ml-3" @click="menu1 = false">확인</v-btn>
                      </div>
                    </v-menu>
                  </td>
                </tr>
              </tbody> 
            </table>
          </div>
        </div>
        <!-- 목록 -->
        <div class="box-ct mt-3">
          <h1 class="tit-h1 d-flex">
            상담현황
          </h1>
          <div class="mt-2">
            <v-data-table
              dense
              v-model="selGroupCd"
              :headers="gridDataHeaders"
              :items="gridDataText"
              :items-per-page="itemsPerPage"
              item-key="index"
              :page.sync="page"
              hide-default-footer
              @page-count="pageCount = $event"
              fixed-header
              class="grid-default"
              height="570px"
              no-data-text="검색된 결과가 없습니다."
            >
            </v-data-table>
            <div class="grid-paging text-center pt-2">
              <span class="grid-total-count">총 <strong>{{ gridDataText.length }}</strong>건</span>
              <v-pagination
                v-model="page"
                :length="pageCount"
                :total-visible="totalVisible"
                next-icon="svg-paging-next"
                prev-icon="svg-paging-prev"
              ></v-pagination>
            </div>
          </div>
        </div>
      </div>
    </div>
  </main>
</template>

<script>
  export default {
    name: "MENU_STM0400", //name은 'MENU_' + 파일명 조합
    components: {
    },
    data() {
      return {
        // 공통코드
        allCodeList  : [],
        USER_AUTH_CHK: '', // 사용자 권한 체크

        // 조회
        CTCP_CODE: '',
        CTCP_NAME: '',
        BUSS_CODE: '',
        CMP_ID   : '',
        menu1    : false,
        DATE_FROM: new Date(Date.now() - new Date().getTimezoneOffset() * 60000).toISOString().substr(0, 10),
        DATE_TO  : new Date(Date.now() - new Date().getTimezoneOffset() * 60000).toISOString().substr(0, 10),
        USE_YN   : false,

        // select box
        ctcpTypeCd: [], // 건설사명
        bussTypeCd: [], // 사업지명
        cmpTypeCd : [], // 캠페인명

        // 현황 목록
        gridDataHeaders: [
          { text: "No", value: "index", align: " d-none", width: "0px", sortable: false  },
        ],
        gridDataText: [], // 상담사 목록 데이터
        selGroupCd  : [],
        BUSS_CD     : '',
        BUSS_NM     : '',
        CMPID       : '',
        CMP_NM      : '',

        // 기타
        GE_USER_ID: '', // 사용자아이디
        monthRange: 0, // 조회기간차이결과
        dateList  : [], // 날짜범위
        dayRange  : 0, // 날짜범위차이

        // 메시지
        stm0200Msg: {
          ctcrCdEmptyChk    : '건설사를 선택하세요.',
          bussNameEmptyChk  : '사업지명을 선택하세요.',
          cnslRsvtDtEmptyChk: '조회기간을 선택하세요.',
          cnslRsvtDtChk     : '조회기간은 3개월만 가능합니다.',
        },

        // 페이징
        page        : 1,
        pageCount   : 0,
        itemsPerPage: 30,
        totalVisible: 10,
      };
    },

    methods: {
      // 기간별고객현황 조회
      async getListData() {
        this.gridDataHeaders = [];

        // 유효성 체크
        if(!this.searchValidate()) {
          return;
        }

        this.monthRange = await this.mixin_selectDiffDate('MONTH', (this.DATE_FROM).replaceAll('-', ''), (this.DATE_TO).replaceAll('-', ''));
        this.dayRange = await this.mixin_selectDiffDate('DAY', (this.DATE_FROM).replaceAll('-', ''), (this.DATE_TO).replaceAll('-', ''));

        if(this.monthRange > 3) {
          this.common_alert(this.stm0200Msg.cnslRsvtDtChk, 'chk');
          return;
        }

        this.getDateRangeData(this.DATE_FROM, this.DATE_TO);

        let requestData = {
          headers: {},
          sendData:{},
        };

        //header 세팅
        requestData.headers["URL"] = "/api/report/report-manage/dayRangeCnslrList";
        requestData.headers["SERVICE"] = "report.report-manage";
        requestData.headers["METHOD"] = "list";
        requestData.headers["TWB_SQL_ID"] = "selectDayRangeCnslrList";
        requestData.headers["ASYNC"] = false;
        requestData.headers["GRID_ID"] = "gridDataHeaders";

        //sendData 초기화
        requestData.sendData["CTCP_CODE"] = this.CTCP_CODE;
        requestData.sendData["BUSS_CODE"] = this.BUSS_CODE;
        requestData.sendData["CMP_ID"] = this.CMP_ID;
        requestData.sendData["START_DATE"] = this.DATE_FROM;
        requestData.sendData["END_DATE"] = this.DATE_TO;
        requestData.sendData["DATE_LIST"] = this.dateList;
        requestData.sendData["DAY_RANGE"] = this.dayRange;

        let response = await this.common_postCall(requestData);

        this.result(response);
      },

      // 기간별고객현황 조회 결과
      getGridDataCallBack(res){
        if(res.HEADER.ERROR_FLAG) {
          this.gridDataText = [];
          return;
        }

        let data = res.DATA;
        let days = data[0].DAYS;
        let cnslrArr = data[0].CNSLR_ARR;
        let daysSum = data[0].DAYS_SUM;

        this.BUSS_CD = data[0].BUSS_CODE;
        this.BUSS_NM = data[0].BUSS_NAME;
        this.CMPID = data[0].CMP_ID;
        this.CMP_NM = data[0].CMP_NAME;

        let idx = 1;

        let cnslrItems = [];
        let daysTotRow = [];

        // 날짜와 건수 분리
        data[0].RESULT.forEach((element, i) => {
          let cnts = [];

          element.forEach((ele, j) => {
            let items = ele.split(':'); //== 2022-09-01:0
            let dateCnt = items[1];
            cnts.push(dateCnt);
          });
          cnslrItems[i] = cnts;
        });

        this.gridDataText = cnslrItems;

        // 헤더 추가
        this.gridDataHeaders.push({ text: "상담사ID", value: "CNSLR_ID",  align: "center", width: "120px", sortable: false },);

        for(var i in days) {
          this.gridDataHeaders.push({ text: this.dateFormat(days[i]), value: "days"+i,  align: "center", width: "80px", sortable: false },)
        }

        this.gridDataHeaders.push({ text: "합계", value: "TOTAL",  align: "center", width: "80px", sortable: false },);

        // 사용자명 표기
        for(var i in this.gridDataText) {
          this.gridDataText[i]["index"] = idx++;
          this.gridDataText[i]["CNSLR_ID"] = cnslrArr[i];
        }

        let tot = 0;
        let totTot = 0;

        // 데이터 잘라서 가로로 나열하기
        this.gridDataText.forEach((element, i) => {
          element.forEach((ele, j) => {
            let items = ele.split(',');

            tot += parseInt(items[0]);

            this.gridDataText[i]["days" + j] = parseInt(items[0]);
            this.gridDataText[i]["TOTAL"] = parseInt(tot);
          });
          totTot += tot;
          tot = 0;
        });

        for(var i in days) {
          daysTotRow.push(daysSum[days[i]]);
        }
        this.gridDataText.push(daysTotRow);
        this.gridDataText[this.gridDataText.length-1]['CNSLR_ID'] = '합계';

        for(var i in days) {
          this.gridDataText[this.gridDataText.length - 1]["days" + i] = daysTotRow[i];
        }
        this.gridDataText[this.gridDataText.length - 1]["TOTAL"] = totTot;
      },

      // 결과 확인
      result(response) {
        if(response.HEADER.METHOD === "list") {
          this.getGridDataCallBack(response);
        }
      },

      // 조회일자 선택
      async startDate(e) {
        this.DATE_FROM = e;
      },

      async endDate(e) {
        this.DATE_TO = e;
      },

      // 두 날짜 사이 날짜 구하기
      getDateRangeData(start, end) {
        this.dateList = [];

        let startDay = new Date(start);
        let endDay = new Date(end);

        while (startDay.getTime() <= endDay.getTime()) {
          let month = startDay.getMonth() + 1;
          month = month < 10 ? '0' + month : month;

          let day = startDay.getDate();
          day = day < 10 ? '0' + day : day;
          
          this.dateList.push(startDay.getFullYear() + '-' + month + '-' + day);
          startDay.setDate(startDay.getDate() + 1);
        }
        return this.dateList;
      },

      // 날짜 포멧 변경
      dateFormat(date) {
        var newDate = "";
        newDate = date.replace(/(\d{4})-(\d{2})-(\d{2})/, '$2/$3');
        return newDate;
      },

      //사업지 코드 가져오기
      async changeBussType(ctcpTypeCd) {
        if(!this.mixin_isEmpty(ctcpTypeCd)) {
          this.BUSS_CODE = '';
          this.bussTypeCd = [];
          this.bussTypeCd.push({ CD: '', CD_NM: '' });
          this.CMP_ID = "";
          this.cmpTypeCd = [];

          if(this.USER_AUTH_CHK === "SYSTEMMANAGER") {
            this.bussTypeCd = await this.mixin_busscode_get(ctcpTypeCd);
          }else {
            this.bussTypeCd = await this.mixin_busscode_get(ctcpTypeCd, this.GE_USER_ID);
          }
        }else {
          this.BUSS_CODE = '';
          this.bussTypeCd = [];
          this.CMP_ID =  "";
          this.cmpTypeCd = [];
        }
      },

      // 캠페인 코드 가져오기
      async changeCmpType(bussTypeCd) {
        if(!this.mixin_isEmpty(bussTypeCd)) {
          this.CMP_ID =  "";
          this.cmpTypeCd = [];
          this.cmpTypeCd.push({ CMP_ID: "", CMP_NAME: "" });
          this.cmpTypeCd = await this.mixin_cmpcode_get(bussTypeCd);
        }else {
          this.CMP_ID =  "";
          this.cmpTypeCd = [];
        }
      },

      // 유효성 체크
      async searchValidate() {
        if(this.mixin_isEmpty(this.CTCP_CODE)) {
          this.common_alert(this.stm0200Msg.ctcrCdEmptyChk, "chk");
          return;
        }

        if(this.mixin_isEmpty(this.BUSS_CODE)) {
          this.common_alert(this.stm0200Msg.bussNameEmptyChk, "chk");
          return;
        }

        if(this.mixin_isEmpty(this.dateRangeText)) {
          this.common_alert(this.stm0200Msg.cnslRsvtDtEmptyChk, 'chk');
          return;
        }

        return true;
      },
    },

    async mounted() {
      let userGroupCd = this.user_role.userGroupCd;
      this.GE_USER_ID = this.user_id;

      let pArr = ['HLW_USE_YN', 'HLW_PLT_AUTH', 'HLW_CTCP_CD'];

      this.allCodeList = await this.mixin_common_code_get_all(pArr);

      if(userGroupCd === "SYSTEMMANAGER") {
        this.USER_AUTH_CHK = userGroupCd;  
        this.ctcpTypeCd = await this.mixin_common_code_get(this.allCodeList, 'HLW_CTCP_CD', '전체');
      }else{
        let usserAuth = await this.mixin_buss_ctcp_code_user_auto(this.USER_ID);
        this.ctcpTypeCd = [{ CD_NM: usserAuth[0].CTCP_NAME, CD: usserAuth[0].CTCP_CODE }];
      }
    },

    computed: {
      dateRangeText() {
        var newStartDate = this.DATE_FROM;
        var newEndDate = this.DATE_TO;
        let returnVal = "";

        if(newStartDate !== '' && newEndDate !== ''){
          returnVal = newStartDate + " ~ " + newEndDate
        }
        return returnVal;
      },
      initHeaders(){
        return {
        };
      },
    },
  };
</script>